import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// This function wraps fetchBaseQuery to include credentials by default
// This would also be where you could include a token or other headers
export const commonBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
    credentials: "include", // Set credentials to include for all requests
    prepareHeaders: (headers) => {
      const xsrfToken = localStorage.getItem("XSRF-TOKEN");
      headers.set("xsrf-token", xsrfToken);
      return headers;
    },
  });

export const api = createApi({
  reducerPath: "api",
  baseQuery: commonBaseQuery(),
  tagTypes: [
    "Accounts",
    "RegisteredCount",
    "MostUsedDevices",
    "MeetingInsights",
  ],
  endpoints: (builder) => ({}),
});

export default api;
