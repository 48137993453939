import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingStatuses: true,
  connectionStatuses: {},
  meetingStatuses: {},
  owlnetStatuses: {},
  isLoadingStatusesV2: true,
  connectionStatusesV2: {},
  meetingStatusesV2: {},
  owlnetStatusesV2: {},
  meetingTimers: {},
};

const iotSlice = createSlice({
  name: "iot",
  initialState,
  reducers: {
    connectionStatusReceived(state, action) {
      const { mac_address, value } = action.payload;
      state.connectionStatuses[mac_address] = value;
    },
    meetingStatusReceived(state, action) {
      const { mac_address, value } = action.payload;
      state.meetingStatuses[mac_address] = value;
    },
    owlnetStatusReceived(state, action) {
      const { mac_address, value } = action.payload;
      state.owlnetStatuses[mac_address] = value;
    },
    IoTStatusesReceived(state, action) {
      state.connectionStatuses = action.payload?.connection_status;
      state.meetingStatuses = action.payload?.meeting_status;
      state.owlnetStatuses = action.payload?.owlnet_status;
      state.isLoadingStatuses = false;
    },
    IoTStatusesReceivedV2(state, action) {
      state.connectionStatusesV2 = action.payload?.connection_status;
      state.meetingStatusesV2 = action.payload?.meeting_status;
      state.owlnetStatusesV2 = action.payload?.owlnet_status;
      state.isLoadingStatusesV2 = false;
    },
    IoTStatusesNotReceivedAfterTimeout(state) {
      state.isLoadingStatuses = false;
    },
    meetingTimersReceived(state, action) {
      state.meetingTimers[action.payload.macAddress] = action.payload.time;
    },
    removeMeetingTimersReceived(state, action) {
      delete state.meetingTimers[action.payload.macAddress];
    },
  },
});

export const {
  connectionStatusReceived,
  meetingStatusReceived,
  owlnetStatusReceived,
  IoTStatusesReceived,
  IoTStatusesReceivedV2,
  IoTStatusesNotReceivedAfterTimeout,
  meetingTimersReceived,
  removeMeetingTimersReceived,
} = iotSlice.actions;

export default iotSlice.reducer;
